import './AllFeaturesTable.scss'

import loadable from '@loadable/component'
import { features, title } from 'process'
import React, { FunctionComponent } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'

import { PricingFeatureCategoryType } from './PricingPage'
const Tooltip = loadable(() => import(`@material-ui/core/Tooltip`))
interface PlansTableType {
  allFeatures: [PricingFeatureCategoryType]
}
const rootClass = `all-features`
const PRICING_PLANS = [`Essential`, `Business`, `Platform`, `Enterprise`]

const AllFeatures: FunctionComponent<PlansTableType> = (props) => {
  return (
    <div className={`${rootClass}`}>
      <div className={`${rootClass}-desktop`}>
        {props.allFeatures.map((item) => (
          <table key={item.contentItemId}>
            <tbody>
              <tr>
                <th>{item.displayText}</th>
                {PRICING_PLANS.map((plan) => (
                  <th key={plan}>{plan === `Essential` ? plan + `s` : plan}</th>
                ))}
              </tr>
              {item.pricingFeatureCategoryItems?.contentItems.map(
                (feature, idx) => (
                  <tr key={idx}>
                    <td>
                      <div>
                        {feature.displayText}
                        {feature.pricingFeatureTooltip && (
                          <Tooltip
                            title={feature.pricingFeatureTooltip}
                            placement='top'
                          >
                            <div>
                              <PuxIconBuilder
                                icon='InfoIcon'
                                options={{
                                  color: {
                                    colorPrimary: `#667088`,
                                  },
                                  hoverColor: {
                                    colorPrimary: `#667088`,
                                  },
                                }}
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </td>
                    {PRICING_PLANS.map((plan) => (
                      <td key={plan}>
                        {(feature as any)[`pricingFeaturePlan${plan}`] && (
                          <PuxIconBuilder
                            key={feature.contentItemId}
                            icon='CheckIcon'
                            options={{
                              color: {
                                colorPrimary: `#00E099`,
                                colorSecondary: `#ffffff`,
                              },
                              hoverColor: {
                                colorPrimary: `#00E099`,
                                colorSecondary: `#ffffff`,
                              },
                            }}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </table>
        ))}
      </div>

      <div className={`${rootClass}-mobile`}>
        {props.allFeatures.map((item) => {
          return item.pricingFeatureCategoryItems?.contentItems.map(
            (feature, idx) => (
              <table key={idx}>
                <tbody>
                  <tr>
                    <th>{feature.displayText}</th>
                    <th></th>
                  </tr>
                  {PRICING_PLANS.map((plan) => (
                    <tr key={plan}>
                      <td>{plan === `Essential` ? plan + `s` : plan}</td>
                      <td>
                        {(feature as any)[`pricingFeaturePlan${plan}`] && (
                          <PuxIconBuilder
                            key={feature.contentItemId}
                            icon='CheckIcon'
                            options={{
                              color: {
                                colorPrimary: `#00E099`,
                                colorSecondary: `#ffffff`,
                              },
                              hoverColor: {
                                colorPrimary: `#00E099`,
                                colorSecondary: `#ffffff`,
                              },
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )
          )
        })}
      </div>
    </div>
  )
}

export default AllFeatures
